<template>
  <form-dialog
    :title="`حجز عيد ميلاد`"
    ref="form"
    :dialog.sync="dialog"
    :load="loadDialog"
    @close="$emit('close')"
    :readonly="readonly"
    @submit="$emit('submit')"
    width="400px"
  >
    <template v-slot:content>
      <v-row>
        <v-col class="d-flex" cols="12" sm="12">
          <v-select
            :items="accounts"
            v-model="form.account_id"
            item-text="name"
            item-value="id"
            label="الحساب"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12">
          <v-alert outlined color="purple">
            <div>
              ان القاعة المخصصة لحجوزات اعياد الميلاد هي
              {{ birthday_classroom.name }}
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="form.date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date"
                label="اختر التاريخ"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date"
              :min="moment.format('YYYY-MM-DD')"
              scrollable
              :max="max"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false"> اغلاق </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(form.date)">
                تأكيد
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" sm="12">
          <v-list dense disabled>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`تكلفة حجز عيد الميلاد الافتراضية : ${birthday_data.birthday_defult_cost} `"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`العدد الافتراضي للحجوزات : ${birthday_data.birthday_defult_count} `"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`تكلفة المدعو الواحد الزائد عن العدد الافتراضي : ${birthday_data.birthday_more_defult_cost} `"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-divider></v-divider>

        <v-col cols="12" sm="12">
          <h5>عدد المدعويين</h5>
          <b-col class="pr-0 custom-control-inline">
            <b-btn
              variant="warning btn-sm mr-1 mt-1 mb-1"
              @click="form.number_of_people = form.number_of_people - 1"
              ><i class="fa fa-minus-circle"></i
            ></b-btn>
            <b-form-input
              type="number"
              v-model="form.number_of_people"
            ></b-form-input>
            <b-btn
              variant="info btn-sm ml-1 mt-1 mb-1"
              @click="form.number_of_people++"
              ><i class="fa fa-plus-circle"></i
            ></b-btn>
          </b-col>
        </v-col>
        <v-col cols="12" sm="12">
          <h5>التكلفة : {{ cost }}</h5>
        </v-col>
      </v-row>
    </template>
  </form-dialog>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import FormDialog from "../../../components/customs/form-dialog.vue";
export default {
  components: { FormDialog },
  props: {
    dialog: Boolean,
    readonly: Boolean,
    edit: Boolean,
    loadDialog: Boolean,
  },
  mounted() {
    this.clear({ type: 2 });
  },
  data() {
    return {
      modal: false,
      modal2: false,
    };
  },
  computed: {
    cost() {
      let cost = 0;
      if (this.form.number_of_people != 0) {
        if (
          this.form.number_of_people > this.birthday_data.birthday_defult_count
        ) {
          const float =
            this.form.number_of_people -
            this.birthday_data.birthday_defult_count;
          cost =
            this.birthday_data.birthday_defult_cost +
            float * this.birthday_data.birthday_more_defult_cost;
        } else {
          cost = this.birthday_data.birthday_defult_cost;
        }
      }
      return cost;
    },
    ...mapGetters("Reservations", [
      "account",
      "form",
      "classrooms",
      "accounts",
      "birthday_data",
      "birthday_classroom",
    ]),
    max() {
      return moment().add("days", 7).format("YYYY-MM-DD");
    },
    moment() {
      return moment();
    },
    today() {
      return moment().format("YYYY-MM-DD");
    },
  },
  methods: {
    ...mapActions("Reservations", ["create", "clear", "filter"]),
  },
  watch: {
    dialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        // this.clear();
      }
      if (this.dialog) {
        this.clear({ type: 1 });
      }
    },
    form: {
      deep: true,
      handler: (newval) => {
        if (newval.number_of_people < 0) {
          newval.number_of_people = 0;
        }
      },
    },
  },
};
</script>

<style>
</style>